"use client"

import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { getCustomerRefferal } from '@/services/Redux/Reducer/CustomerSlice';
import localStorageCall from '@/services/Methods/localstorage.hook';
import { storeAssigned } from '@/services/Methods/normalMethods';
import { getAllCustomerData } from '@/services/Redux/Reducer/LoginSlice';
import { useRouter } from 'next/navigation';

const UseCreateAccountHook = (props) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [searcheddata, setSearcheddata] = useState('form')
    const [offset, setOffSet] = useState(1);

    const [userField, setUserField] = useState();
    const [isLoadingTrue, setIsLoadingTrue] = useState(false);
    const [navigateLink, setNavigateLink] = useState('');
    const [isCustomerLoggedIn, setIsCustomerLoggedIn] = useState(false);
    const [webAliesError, setWebAliesErros] = useState({
        isWebAliesError: "",
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });
    const dispatch = useDispatch();
    const router = useRouter();
    const handleChange = (values) => {
        setUserField(values);
    }
    function _navigateFunction(link) {
        window.location.assign(`/${storeAssigned()}/${link}`)
    }
    function _validateRefferalFunction(section) {
        setIsLoadingTrue(true);
        if (userField) {
            dispatch(getCustomerRefferal((data) => {
                // console.log("inside-the-validation-referral-function----------------------")
                setIsLoadingTrue(false);
                if (data?.length === 0) {
                    setOpenPopup(false)
                    _navigateFunction(section);
                } else {
                    setOpenPopup(false);
                    setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
                    if (data[0]?.enrollerId !== 0) {

                        dispatch(getAllCustomerData((newData) => {
                            // console.log("newData", newData)
                            const refferalData = JSON.stringify({ "id": newData?.customerId, "name": newData?.fullName || newData?.publicProfile?.fullName, "webalies": newData?.webAlias, userDetail: data?.[0] });
                            localStorageCall().setItem("refferal_link", refferalData);
                            if (data[0]?.customerType === 1 || data[0]?.customerType === 5) {
                                localStorageCall().setItem('Token', data[0]?.customerId)
                                _navigateFunction(section);
                            }
                            else {
                                _navigateFunction(`signin?redirectTo=${section}`);
                            }
                        }, data[0]?.enrollerId))
                    }
                }
            }, userField, "phoneNumber"));
        }
    };

    return ({
        openPopup, setOpenPopup, userField, setUserField, webAliesError, setWebAliesErros, handleChange, _validateRefferalFunction, isLoadingTrue,
        setNavigateLink, navigateLink, _navigateFunction, isCustomerLoggedIn, setIsCustomerLoggedIn, searcheddata, setSearcheddata,
        offset, setOffSet
    })
}

export default UseCreateAccountHook;